import { NgModule } from '@angular/core';
import { NgxPrintDirective } from './ngx-print.directive';

@NgModule({
  declarations: [NgxPrintDirective],
  imports: [
  ],
  exports: [NgxPrintDirective]
})
export class NgxPrintModule { }
