import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastComponent } from './toast.component';
import { ToasterContainerComponent } from './toaster-container.component';
import { ToasterService } from './toaster.service';



export class ToasterModule { static decorators: DecoratorInvocation[] = [
{ type: NgModule, args: [{
    imports: [CommonModule],
    declarations: [
        ToastComponent, 
        ToasterContainerComponent
    ],
    providers: [ToasterService],
    exports: [
        ToasterContainerComponent, 
        ToastComponent
    ]
}, ] },
];
/** @nocollapse */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
];
}
interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
