import * as noUiSlider from 'nouislider';
import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  OnChanges,
  Output,
  Renderer2,
  NgZone,
  OnDestroy
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR
} from '@angular/forms';

export interface NouiFormatter {
  to(value: number): string;
  from(value: string): number;
}
export class DefaultFormatter implements NouiFormatter {
/**
 * @param {?} value
 * @return {?}
 */
to(value: number): string {
    // formatting with http://stackoverflow.com/a/26463364/478584
    return String(parseFloat(parseFloat(String(value)).toFixed(2)));
  };
/**
 * @param {?} value
 * @return {?}
 */
from(value: string): number {
    return parseFloat(value);
  }
}
export class NouisliderComponent implements ControlValueAccessor, OnInit, OnChanges, OnDestroy {
public slider: any;
public handles: any[];
public disabled: boolean;
public behaviour: string;
public connect: boolean[];
public limit: number;
public min: number;
public max: number;
public snap: boolean;
public animate: boolean | boolean[];
public range: any;
public step: number;
public format: NouiFormatter;
public pageSteps: number;
public config: any = {};
public ngModel: number | number[];
public keyboard: boolean;
public onKeydown: any;
public formControl: FormControl;
public tooltips: Array<any>;
public change: EventEmitter<any> = new EventEmitter(true);
public update: EventEmitter<any> = new EventEmitter(true);
public slide: EventEmitter<any> = new EventEmitter(true);
public set: EventEmitter<any> = new EventEmitter(true);
public start: EventEmitter<any> = new EventEmitter(true);
public end: EventEmitter<any> = new EventEmitter(true);
private value: any;
private onChange: any = Function.prototype;
private cleanups: VoidFunction[] = [];
/**
 * @param {?} ngZone
 * @param {?} el
 * @param {?} renderer
 */
constructor(private ngZone: NgZone,
private el: ElementRef,
private renderer : Renderer2) { }
/**
 * @return {?}
 */
ngOnInit(): void {
    let /** @type {?} */ inputsConfig = JSON.parse(
      JSON.stringify({
        behaviour: this.behaviour,
        connect: this.connect,
        limit: this.limit,
        start: this.formControl !== undefined ? this.formControl.value : this.ngModel,
        step: this.step,
        pageSteps: this.pageSteps,
        keyboard: this.keyboard,
        onKeydown: this.onKeydown,
        range: this.range || this.config.range || { min: this.min, max: this.max },
        tooltips: this.tooltips,
        snap: this.snap,
        animate: this.animate,
      }),
    );
    inputsConfig.tooltips = this.tooltips || this.config.tooltips;
    inputsConfig.format = this.format || this.config.format || new DefaultFormatter();

    this.ngZone.runOutsideAngular(() => {
      this.slider = noUiSlider.create(
        this.el.nativeElement.querySelector('div'),
        Object.assign(this.config, inputsConfig)
      );
    });

    this.handles = [].slice.call(this.el.nativeElement.querySelectorAll('.noUi-handle'));

    if (this.config.keyboard) {
      if (this.config.pageSteps === undefined) {
        this.config.pageSteps = 10;
      }

      for (const /** @type {?} */ handle of this.handles) {
        handle.setAttribute('tabindex', 0);

        const /** @type {?} */ onKeydown = this.config.onKeydown || this.defaultKeyHandler;

        this.ngZone.runOutsideAngular(() => {
          this.cleanups.push(
            this.renderer.listen(handle, 'keydown', onKeydown),
            this.renderer.listen(handle, 'click', () => {
              handle.focus();
            }),
          );
        });
      }
    }

    this.slider.on('set', (values: string[], handle: number, unencoded: number[]) => {
      this.eventHandler(this.set, values, handle, unencoded);
    });

    this.slider.on('update', (values: string[], handle: number, unencoded: number[]) => {
      if (this.update.observers.length > 0) {
        this.ngZone.run(() => {
          this.update.emit(this.toValues(values));
        });
      }
    });

    this.slider.on('change', (values: string[], handle: number, unencoded: number[]) => {
      if (this.change.observers.length > 0) {
        this.ngZone.run(() => {
          this.change.emit(this.toValues(values));
        });
      }
    });

    this.slider.on('slide', (values: string[], handle: number, unencoded: number[]) => {
      this.eventHandler(this.slide, values, handle, unencoded);
    });

    this.slider.on('start', (values: string[], handle: number, unencoded: number[]) => {
      if (this.start.observers.length > 0) {
        this.ngZone.run(() => {
          this.start.emit(this.toValues(values));
        });
      }
    });

    this.slider.on('end', (values: string[], handle: number, unencoded: number[]) => {
      if (this.end.observers.length > 0) {
        this.ngZone.run(() => {
          this.end.emit(this.toValues(values));
        });
      }
    });
  }
/**
 * @param {?} changes
 * @return {?}
 */
ngOnChanges(changes: any) {
    if (this.slider && (changes.min || changes.max || changes.step || changes.range)) {
      this.ngZone.runOutsideAngular(() => {
        setTimeout(() => {
          this.slider.updateOptions({
            range: Object.assign({}, {
              min: this.min,
              max: this.max
            }, this.range || {}),
            step: this.step
          });
        });
      });
    }
  }
/**
 * @return {?}
 */
ngOnDestroy(): void {
    this.slider.destroy();

    while (this.cleanups.length) {
      this.cleanups.pop()();
    }
  }
/**
 * @param {?} values
 * @return {?}
 */
toValues(values: string[]): any | any[] {
    let /** @type {?} */ v = values.map(this.config.format.from);
    return (v.length == 1 ? v[0] : v);
  }
/**
 * @param {?} value
 * @return {?}
 */
writeValue(value: any): void {
    if (this.slider) {
      this.ngZone.runOutsideAngular(() => {
        setTimeout(() => {
          this.slider.set(value);
        });
      });
    }
  }
/**
 * @param {?} fn
 * @return {?}
 */
registerOnChange(fn: (value: any) => void) {
    this.onChange = fn;
  }
/**
 * @param {?} fn
 * @return {?}
 */
registerOnTouched(fn: () => {}): void {}
/**
 * @param {?} isDisabled
 * @return {?}
 */
setDisabledState(isDisabled: boolean): void {
    isDisabled
      ? this.renderer.setAttribute(this.el.nativeElement.childNodes[0], 'disabled', 'true')
      : this.renderer.removeAttribute(this.el.nativeElement.childNodes[0], 'disabled');
  }
private eventHandler = (emitter: EventEmitter<any>, values: string[], handle: number, unencoded: number[]) => {
    let /** @type {?} */ v = this.toValues(values);
    let /** @type {?} */ emitEvents = false;
    if(this.value === undefined) {
      this.value = v;
      return;
    }
    if(Array.isArray(v) && this.value[handle] != v[handle]) {
      emitEvents = true;
    }
    if(!Array.isArray(v) && this.value != v) {
      emitEvents = true;
    }
    if(emitEvents) {
      this.ngZone.run(() => {
        if (emitter.observers.length > 0) {
          emitter.emit(v);
        }
        this.onChange(v);
      });
    }
    if(Array.isArray(v)) {
      this.value[handle] = v[handle];
    } else {
      this.value = v;
    }
  }
private defaultKeyHandler = (e: KeyboardEvent) => {
    let /** @type {?} */ stepSize: any[] = this.slider.steps();
    let /** @type {?} */ index = parseInt(( /** @type {?} */((<HTMLElement>e.target))).getAttribute('data-handle'));
    let /** @type {?} */ sign = 1;
    let /** @type {?} */ multiplier: number = 1;
    let /** @type {?} */ step = 0;
    let /** @type {?} */ delta = 0;

    switch ( e.which ) {
      case 34:  // PageDown
        multiplier = this.config.pageSteps;
      case 40:  // ArrowDown
      case 37:  // ArrowLeft
        sign = -1;
        step = stepSize[index][0];
        e.preventDefault();
        break;

      case 33:  // PageUp
        multiplier = this.config.pageSteps;
      case 38:  // ArrowUp
      case 39:  // ArrowRight
        step = stepSize[index][1];
        e.preventDefault();
        break;

      default:
        break;
    }

    delta = sign * multiplier * step;
    let /** @type {?} */ newValue: number | number[];

    if(Array.isArray(this.value)) {
      newValue = [].concat(this.value);
      newValue[index] = newValue[index] + delta;
    } else {
      newValue = this.value + delta;
    }

    this.slider.set(newValue);
  }
static decorators: DecoratorInvocation[] = [
{ type: Component, args: [{
  selector: 'nouislider',
  host: {
    '[class.ng2-nouislider]': 'true'
  },
  template: '<div [attr.disabled]="disabled ? true : undefined"></div>',
  styles: [`
    :host {
      display: block;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  `],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NouisliderComponent),
      multi: true
    }
  ]
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
{type: NgZone, },
{type: ElementRef, },
{type: Renderer2, },
];
static propDecorators: {[key: string]: DecoratorInvocation[]} = {
'disabled': [{ type: Input },],
'behaviour': [{ type: Input },],
'connect': [{ type: Input },],
'limit': [{ type: Input },],
'min': [{ type: Input },],
'max': [{ type: Input },],
'snap': [{ type: Input },],
'animate': [{ type: Input },],
'range': [{ type: Input },],
'step': [{ type: Input },],
'format': [{ type: Input },],
'pageSteps': [{ type: Input },],
'config': [{ type: Input },],
'ngModel': [{ type: Input },],
'keyboard': [{ type: Input },],
'onKeydown': [{ type: Input },],
'formControl': [{ type: Input },],
'tooltips': [{ type: Input },],
'change': [{ type: Output },],
'update': [{ type: Output },],
'slide': [{ type: Output },],
'set': [{ type: Output },],
'start': [{ type: Output },],
'end': [{ type: Output },],
};
}

function NouisliderComponent_tsickle_Closure_declarations() {
/** @type {?} */
NouisliderComponent.decorators;
/**
 * @nocollapse
 * @type {?}
 */
NouisliderComponent.ctorParameters;
/** @type {?} */
NouisliderComponent.propDecorators;
/** @type {?} */
NouisliderComponent.prototype.slider;
/** @type {?} */
NouisliderComponent.prototype.handles;
/** @type {?} */
NouisliderComponent.prototype.disabled;
/** @type {?} */
NouisliderComponent.prototype.behaviour;
/** @type {?} */
NouisliderComponent.prototype.connect;
/** @type {?} */
NouisliderComponent.prototype.limit;
/** @type {?} */
NouisliderComponent.prototype.min;
/** @type {?} */
NouisliderComponent.prototype.max;
/** @type {?} */
NouisliderComponent.prototype.snap;
/** @type {?} */
NouisliderComponent.prototype.animate;
/** @type {?} */
NouisliderComponent.prototype.range;
/** @type {?} */
NouisliderComponent.prototype.step;
/** @type {?} */
NouisliderComponent.prototype.format;
/** @type {?} */
NouisliderComponent.prototype.pageSteps;
/** @type {?} */
NouisliderComponent.prototype.config;
/** @type {?} */
NouisliderComponent.prototype.ngModel;
/** @type {?} */
NouisliderComponent.prototype.keyboard;
/** @type {?} */
NouisliderComponent.prototype.onKeydown;
/** @type {?} */
NouisliderComponent.prototype.formControl;
/** @type {?} */
NouisliderComponent.prototype.tooltips;
/** @type {?} */
NouisliderComponent.prototype.change;
/** @type {?} */
NouisliderComponent.prototype.update;
/** @type {?} */
NouisliderComponent.prototype.slide;
/** @type {?} */
NouisliderComponent.prototype.set;
/** @type {?} */
NouisliderComponent.prototype.start;
/** @type {?} */
NouisliderComponent.prototype.end;
/** @type {?} */
NouisliderComponent.prototype.value;
/** @type {?} */
NouisliderComponent.prototype.onChange;
/** @type {?} */
NouisliderComponent.prototype.cleanups;
/** @type {?} */
NouisliderComponent.prototype.eventHandler;
/** @type {?} */
NouisliderComponent.prototype.defaultKeyHandler;
/** @type {?} */
NouisliderComponent.prototype.ngZone;
/** @type {?} */
NouisliderComponent.prototype.el;
/** @type {?} */
NouisliderComponent.prototype.renderer;
}


interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
